import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AdminPage extends Vue {
  menuItems = [
    { title: 'Users online', link: 'users-online' },
    { title: 'Scheduled reports', link: 'scheduled-reports' },
    { title: 'Notifications', link: 'notifications' },
    { title: 'SMTP logs', link: 'smtp-logs' }
  ]
}
